import {Button, Dialog, DialogBody, Spinner} from "@material-tailwind/react"
import React, {useEffect, useState} from "react"
import {FaTimes} from "react-icons/fa"
import {IoMdEye, IoMdEyeOff} from "react-icons/io"
import {forgotPassword, generateOtp, getUser, verifyOtp} from "../service/api"
import {useDispatch, useSelector} from "react-redux"
import actions from "../redux/user/actions"
import {useAddress} from "@thirdweb-dev/react-core"
import {toast} from "react-toastify"

const LoginModel = ({login, setLogin, setRegister}) => {
  const user = useSelector((state) => state.user)
  // console.log("%c Line:11 🍫 user", "color:#ea7e5c", user)
  const dispatch = useDispatch()
  const address = useAddress()
  const [modal, setModal] = useState("Login")
  const [otp, setOtp] = useState()
  const [noOtp, setNoOtp] = useState(true)
  const [otpErr, setOtpErr] = useState(false)
  const [payload, setPayload] = useState({
    email: "",
    password: "",
  })
  // console.log("%c Line:17 🌰 payload", "color:#465975", payload)
  const [payloadErr, setPayloadErr] = useState({
    email: false,
    password: false,
  })
  // console.log("%c Line:21 🍉 payloadErr", "color:#93c0a4", payloadErr)
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState()
  const [passwordShow, setPasswordShow] = useState(false)

  const handleChange = (e) => {
    e.preventDefault()
    setPayload({...payload, [e.target.name]: e.target.value})
    setPayloadErr({...payloadErr, [e.target.name]: false})
  }
  const handleUser = () => {
    if (address) {
      dispatch(actions.setLogin(false))
      dispatch(actions.setRegister(true))
    } else {
      setErrMsg("Please Connect Wallet")
    }
  }

  useEffect(() => {
    const reset = setTimeout(() => {
      setErrMsg()
    }, 10000)

    return () => clearTimeout(reset)
  }, [errMsg])

  const sendOtp = () => {
    if (payload.email === "" || payload?.email === null) {
      setPayloadErr({...payloadErr, email: true})
      setErrMsg("Please Enter Email")
      return
    }
    setLoading(true)
    let body = {email: payload?.email}
    generateOtp(body)
      .then((res) => {
        console.log("%c Line:62 🍭 res", "color:#b03734", res)
        if (res?.status) {
          setNoOtp(false)
          setErrMsg(res?.message)
        }
        setLoading(false)
      })
      .catch((e) => {
        console.log("%c Line:65 🍯 e", "color:#33a5ff", e)
        setLoading(false)
      })
  }

  const validateOtp = () => {
    if (!otp) {
      setOtpErr(true)
      setErrMsg("Please Enter Otp")
      return
    }
    setLoading(true)
    let body = {email: payload?.email, code: otp}
    verifyOtp(body)
      .then((res) => {
        console.log("%c Line:88 🍊 res", "color:#ffdd4d", res)
        if (res?.status) {
          setModal("Forgot")
          setErrMsg(res?.message)
        }
        setLoading(false)
      })
      .catch((e) => {
        console.log("%c Line:65 🍯 e", "color:#33a5ff", e)
        setLoading(false)
      })
  }

  const handleForgot = () => {
    for (let key in payload) {
      if (payload[key] === null || payload[key] === "") {
        setPayloadErr((prevState) => ({...prevState, [key]: true}))
      }
    }
    if (payload?.email === "" || payload?.password === "") {
      return
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!emailRegex.test(payload?.email)) {
      setErrMsg("Please Enter Valid Email")
      return
    }
    setLoading(true)
    forgotPassword(payload)
      .then((res) => {
        console.log("%c Line:119 🧀 res", "color:#7f2b82", res)
        if (res?.status) {
          setPayload({
            email: "",
            password: "",
          })
          setModal("Login")
        }
        setLoading(false)
      })
      .catch((e) => {
        console.log("%c Line:42 🥤 e", "color:#f5ce50", e)
        setLoading(false)
        setErrMsg(e?.response?.data?.message)
      })
  }
  const handleLogin = () => {
    for (let key in payload) {
      if (payload[key] === null || payload[key] === "") {
        setPayloadErr((prevState) => ({...prevState, [key]: true}))
      }
    }
    if (payload?.email === "" || payload?.password === "") {
      return
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!emailRegex.test(payload?.email)) {
      setErrMsg("Please Enter Valid Email")
      return
    }
    setLoading(true)
    getUser(payload)
      .then((res) => {
        console.log("%c Line:40 🍞 res", "color:#3f7cff", res)
        if (res?.status) {
          setPayload({
            email: "",
            password: "",
          })
          dispatch(actions.setUser(res?.data?.userData))
          dispatch(actions.setToken(res?.data?.token))
          dispatch(actions.setLoggedIn(true))
          dispatch(actions.setLogin(false))
        }
        toast.success(res?.message)
        setLoading(false)
      })
      .catch((e) => {
        console.log("%c Line:42 🥤 e", "color:#f5ce50", e)
        setLoading(false)
        setErrMsg(e?.response?.data?.message)
      })
  }
  return (
    <Dialog
      open={user?.login}
      //   handler={() => setLogin(!login)}
      animate={{
        mount: {scale: 1, y: 0},
        unmount: {scale: 0.9, y: -100},
      }}
      size="xs"
      className="rounded-lg dark:bg-darkSidebar">
      <DialogBody className="p-0">
        {modal === "Login" ? (
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder flex items-center justify-between px-5 py-3">
            <h5 className="text-base font-proxima-bold text-black dark:text-white leading-none">
              Login
            </h5>
            <Button
              className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 rounded-full p-2"
              onClick={() => dispatch(actions.setLogin(false))}>
              <FaTimes className="w-3 h-3 text-[#777]" />
            </Button>
          </div>
        ) : (
          <div className="border-b border-[#E5E5E5] dark:border-darkBorder flex items-center justify-between px-5 py-3">
            <h5 className="text-base font-proxima-bold text-black dark:text-white leading-none">
              Forgot Password
            </h5>
            <Button
              className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 rounded-full p-2"
              onClick={() => dispatch(actions.setLogin(false))}>
              <FaTimes className="w-3 h-3 text-[#777]" />
            </Button>
          </div>
        )}
        {modal === "Login" && (
          <div className="flex flex-col items-start justify-center gap-3 p-5 w-full">
            {errMsg && <p className="text-red-900 text-[14px]">{errMsg}</p>}
            <div className="flex flex-col items-start justify-center gap-1 w-full">
              <p className="text-black text-[14px]">Email</p>
              <input
                placeholder="Email"
                type="email"
                className={`border ${
                  payloadErr?.email ? "border-red-800" : "border-[#e5e5e5]"
                } outline-none text-black rounded-[10px] w-full p-2`}
                value={payload?.email}
                name="email"
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col items-start justify-center gap-1 w-full">
              <p className="text-black text-[14px]">Password</p>{" "}
              <div className="relative w-full">
                <input
                  placeholder="******"
                  type={passwordShow ? "text" : "password"}
                  value={payload?.password}
                  name="password"
                  onChange={handleChange}
                  className={`border ${
                    payloadErr?.password ? "border-red-800" : "border-[#e5e5e5]"
                  } outline-none text-black rounded-[10px] w-full p-2`}
                />{" "}
                <Button
                  className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-1 right-1"
                  onClick={() => setPasswordShow(!passwordShow)}>
                  {passwordShow ? (
                    <IoMdEyeOff className="w-5 h-5 text-black" />
                  ) : (
                    <IoMdEye className="w-5 h-5 text-black" />
                  )}
                </Button>
              </div>
            </div>
            <div
              onClick={() => setModal("Otp")}
              className="w-full text-right cursor-pointer text-black text-[14px]">
              Forgot Password?
            </div>
            <Button
              onClick={handleLogin}
              className="w-full bg-[#e7b900] cursor-pointer"
              disabled={loading}>
              {loading ? <Spinner className="w-full text-center" /> : "Login"}
            </Button>
            <div
              className="w-full text-center cursor-pointer text-black text-[14px]"
              onClick={handleUser}>
              New User?
            </div>
          </div>
        )}
        {modal === "Forgot" && (
          <div className="flex flex-col items-start justify-center gap-3 p-5 w-full">
            {errMsg && <p className="text-red-900 text-[14px]">{errMsg}</p>}
            <div className="flex flex-col items-start justify-center gap-1 w-full">
              <p className="text-black text-[14px]">Email</p>
              <input
                placeholder="Email"
                type="email"
                className={`border ${
                  payloadErr?.email ? "border-red-800" : "border-[#e5e5e5]"
                } outline-none text-black rounded-[10px] w-full p-2`}
                value={payload?.email}
                name="email"
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col items-start justify-center gap-1 w-full">
              <p className="text-black text-[14px]">New Password</p>{" "}
              <div className="relative w-full">
                <input
                  placeholder="******"
                  type={passwordShow ? "text" : "password"}
                  value={payload?.password}
                  name="password"
                  onChange={handleChange}
                  className={`border ${
                    payloadErr?.password ? "border-red-800" : "border-[#e5e5e5]"
                  } outline-none text-black rounded-[10px] w-full p-2`}
                />{" "}
                <Button
                  className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-1 right-1"
                  onClick={() => setPasswordShow(!passwordShow)}>
                  {passwordShow ? (
                    <IoMdEyeOff className="w-5 h-5 text-black" />
                  ) : (
                    <IoMdEye className="w-5 h-5 text-black" />
                  )}
                </Button>
              </div>
            </div>
            <div
              onClick={() => setModal("Otp")}
              className="w-full text-right cursor-pointer text-black text-[14px]">
              Forgot Password?
            </div>
            <Button
              onClick={handleForgot}
              className="w-full bg-[#e7b900] cursor-pointer"
              disabled={loading}>
              {loading ? (
                <Spinner className="w-full text-center" />
              ) : (
                "Update Password"
              )}
            </Button>
            {/* <div
              className="w-full text-center cursor-pointer text-black text-[14px]"
              onClick={handleUser}>
              New User?
            </div> */}
          </div>
        )}
        {modal === "Otp" && (
          <div className="flex flex-col items-start justify-center gap-3 p-5 w-full">
            {errMsg && <p className="text-red-900 text-[14px]">{errMsg}</p>}
            <div className="flex flex-col items-start justify-center gap-1 w-full">
              <p className="text-black text-[14px]">Email</p>
              <input
                placeholder="Email"
                type="email"
                className={`border ${
                  payloadErr?.email ? "border-red-800" : "border-[#e5e5e5]"
                } outline-none text-black rounded-[10px] w-full p-2`}
                value={payload?.email}
                name="email"
                readOnly={!noOtp}
                onChange={handleChange}
              />
            </div>

            <div
              className={`${
                noOtp ? "hidden" : "flex"
              } flex-col items-start justify-center gap-1 w-full`}>
              <p className="text-black text-[14px]">Otp</p>{" "}
              <input
                placeholder="Otp"
                type="number"
                className={`border ${
                  otpErr ? "border-red-800" : "border-[#e5e5e5]"
                } outline-none text-black rounded-[10px] w-full p-2`}
                value={otp}
                name="Otp"
                disabled={noOtp}
                onChange={(e) => {
                  setOtp(e.target.value)
                  setOtpErr(false)
                }}
              />
            </div>

            {noOtp ? (
              <Button
                onClick={sendOtp}
                className="w-full bg-[#e7b900] cursor-pointer"
                disabled={loading}>
                {loading ? (
                  <Spinner className="w-full text-center" />
                ) : (
                  "Send Otp"
                )}
              </Button>
            ) : (
              <Button
                onClick={validateOtp}
                className="w-full bg-[#e7b900] cursor-pointer"
                disabled={loading}>
                {loading ? (
                  <Spinner className="w-full text-center" />
                ) : (
                  "Verify Otp"
                )}
              </Button>
            )}
            <div
              className="w-full text-center cursor-pointer text-black text-[14px]"
              onClick={() => setModal("Login")}>
              Login?
            </div>
          </div>
        )}
      </DialogBody>
    </Dialog>
  )
}

export default LoginModel
