import { Button, Dialog, DialogBody, Spinner } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import actions from "../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createUser } from "../service/api";
import { useAddress } from "@thirdweb-dev/react-core";

const RegisterModal = ({ register, setRegister, setLogin }) => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const address = useAddress();
    const [payload, setPayload] = useState({
        name: "",
        userName: "",
        mobile: "",
        email: "",
        password: "",
        walletAddress: "",
    });
    const [payloadErr, setPayloadErr] = useState({
        name: false,
        userName: false,
        mobile: false,
        email: false,
        password: false,
        walletAddress: false,
    });

    const [loading, setLoading] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [errMsg, setErrMsg] = useState();

    const handleChange = (e) => {
        e.preventDefault();
        setPayload({ ...payload, [e.target.name]: e.target.value });
        setPayloadErr({ ...payloadErr, [e.target.name]: false });
    };

    useEffect(() => {
        const reset = setTimeout(() => {
            setErrMsg();
        }, 10000);

        return () => clearTimeout(reset);
    }, [errMsg]);

    useEffect(() => {
        if (address) {
            setPayload({ ...payload, walletAddress: address });
        }
    }, [address]);

    const handleRegister = () => {
        for (let key in payload) {
            if (payload[key] === null || payload[key] === "") {
                setPayloadErr((prevState) => ({ ...prevState, [key]: true }));
            }
        }
        if (
            payload?.email === "" ||
            payload?.name === "" ||
            payload?.userName === "" ||
            payload?.password === "" ||
            payload?.mobile === "" ||
            payload?.walletAddress === ""
        ) {
            return;
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(payload?.email)) {
            setErrMsg("Please Enter Valid Email");
            return;
        }
        setLoading(true);
        createUser(payload)
            .then((res) => {
                console.log("%c Line:40 🍞 res", "color:#3f7cff", res);
                if (res?.status) {
                    setPayload({
                        name: "",
                        userName: "",
                        mobile: "",
                        email: "",
                        password: "",
                        walletAddress: "",
                    });
                    dispatch(actions.setUser(res?.data?.userData));
                    dispatch(actions.setToken(res?.data?.token));
                    dispatch(actions.setLoggedIn(true));
                    dispatch(actions.setRegister(false));
                }
                toast.success(res?.message);
                setLoading(false);
            })
            .catch((e) => {
                console.log("%c Line:42 🥤 e", "color:#f5ce50", e);
                setLoading(false);
                setErrMsg(e?.response?.data?.message);
            });
    };

    return (
        <>
            {" "}
            <Dialog
                open={user?.register}
                // handler={() => setRegister(!register)}
                animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0.9, y: -100 },
                }}
                size="xs"
                className="rounded-lg dark:bg-darkSidebar"
            >
                <DialogBody className="p-0">
                    <div className="border-b border-[#E5E5E5] dark:border-darkBorder flex items-center justify-between px-5 py-3">
                        <h5 className="text-base font-proxima-bold text-black dark:text-white leading-none">
                            Register
                        </h5>
                        <Button
                            className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 rounded-full p-2"
                            onClick={() => dispatch(actions.setRegister(false))}
                        >
                            <FaTimes className="w-3 h-3 text-[#777]" />
                        </Button>
                    </div>
                    <div className="flex flex-col items-start justify-center gap-3 p-5 w-full min-h-[500px] overflow-y-auto h-[410px] sm:h-auto">
                        {errMsg && (
                            <p className="text-red-900 text-[14px]">{errMsg}</p>
                        )}
                        <div className="flex flex-col items-start justify-center gap-1 w-full">
                            <p className="text-black text-[14px]">Name</p>
                            <input
                                placeholder="abc"
                                type="text"
                                className={`border ${
                                    payloadErr?.name
                                        ? "border-red-800"
                                        : "border-[#e5e5e5]"
                                } outline-none text-black rounded-[10px] w-full p-2`}
                                value={payload?.name}
                                name="name"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex flex-col items-start justify-center gap-1 w-full">
                            <p className="text-black text-[14px]">Username</p>
                            <input
                                placeholder="Username"
                                type="text"
                                className={`border ${
                                    payloadErr?.userName
                                        ? "border-red-800"
                                        : "border-[#e5e5e5]"
                                } outline-none text-black rounded-[10px] w-full p-2`}
                                value={payload?.userName}
                                name="userName"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex flex-col items-start justify-center gap-1 w-full">
                            <p className="text-black text-[14px]">Email</p>
                            <input
                                placeholder="Email"
                                type="email"
                                className={`border ${
                                    payloadErr?.email
                                        ? "border-red-800"
                                        : "border-[#e5e5e5]"
                                } outline-none text-black rounded-[10px] w-full p-2`}
                                value={payload?.email}
                                name="email"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex flex-col items-start justify-center gap-1 w-full">
                            <p className="text-black text-[14px]">Country</p>
                            <input
                                placeholder="Country"
                                type="text"
                                className={`border ${
                                    payloadErr?.mobile
                                        ? "border-red-800"
                                        : "border-[#e5e5e5]"
                                } outline-none text-black rounded-[10px] w-full p-2`}
                                value={payload?.mobile}
                                name="mobile"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex flex-col items-start justify-center gap-1 w-full">
                            <p className="text-black text-[14px]">Password</p>{" "}
                            <div className="relative w-full">
                                <input
                                    placeholder="******"
                                    type={passwordShow ? "text" : "password"}
                                    value={payload?.password}
                                    name="password"
                                    onChange={handleChange}
                                    className={`border ${
                                        payloadErr?.password
                                            ? "border-red-800"
                                            : "border-[#e5e5e5]"
                                    } outline-none text-black rounded-[10px] w-full p-2`}
                                />{" "}
                                <Button
                                    className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-1 right-1"
                                    onClick={() =>
                                        setPasswordShow(!passwordShow)
                                    }
                                >
                                    {passwordShow ? (
                                        <IoMdEyeOff className="w-5 h-5 text-black" />
                                    ) : (
                                        <IoMdEye className="w-5 h-5 text-black" />
                                    )}
                                </Button>
                            </div>
                        </div>
                        <div className="flex flex-col items-start justify-center gap-1 w-full">
                            <p className="text-black text-[14px]">
                                Wallet address
                            </p>
                            <input
                                placeholder="wallet address"
                                type="text"
                                className={`border ${
                                    payloadErr?.walletAddress
                                        ? "border-red-800"
                                        : "border-[#e5e5e5]"
                                } outline-none text-black rounded-[10px] w-full p-2`}
                                value={address}
                                name="walletAddress"
                                readOnly
                                // onChange={handleChange}
                            />
                        </div>
                        <Button
                            className="w-full bg-[#e7b900] cursor-pointer"
                            onClick={handleRegister}
                            disabled={loading}
                        >
                            {loading ? (
                                <Spinner className="text-center w-full" />
                            ) : (
                                "Register"
                            )}
                        </Button>
                        <div
                            className="w-full text-center cursor-pointer text-black text-[14px]"
                            onClick={() => {
                                dispatch(actions.setLogin(true));
                                dispatch(actions.setRegister(false));
                            }}
                        >
                            Already User?
                        </div>
                    </div>
                </DialogBody>
            </Dialog>
        </>
    );
};

export default RegisterModal;
