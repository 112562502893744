import React, { useState, useEffect } from "react";
import logo from "../Assets/Images/Logo.svg";
import { ConnectWallet, useAddress, useChainId } from "@thirdweb-dev/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../redux/user/actions";
import { toast } from "react-toastify";
import { CgProfile } from "react-icons/cg";

const Navbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const address = useAddress();
    const chainId = useChainId();
    const [showErr, setShowErr] = useState(false);

    useEffect(() => {
        if (!showErr && chainId && chainId != 56) {
            console.log("%c Line:76 🍺 chainId", "color:#f5ce50", chainId);
            setShowErr(true);
            if (parseInt(new Date().getTime() / 1000) > 1711044000)
                toast.error(`Please connect to BSC Network`);
        }
    }, [chainId]);

    return (
        <div
            style={{ marginTop: "20px" }}
            className="mx-auto w-full sm:w-[85%] px-2 sm:px-0 flex justify-center items-center h-[60px] bg-[#010101]"
        >
            <div className="flex items-center bg-[#010101] justify-between float-center py-4 px-2 sm:px-8 w-full mx-auto ">
                <img
                    alt="bnb"
                    width={"200"}
                    src={'https://www.blockchain.uzh.ch/wp-content/uploads/bnb-chain-full-binance-smart-chain-logo-1-1024x180.png'}
                    className="cursor-pointer"
                    onClick={() => navigate("/")}
                />
                {/* {user?.loggedIn && address ? (
                    <>
                        <CgProfile
                            onClick={() => navigate("/profile")}
                            className="text-white size-[28px] cursor-pointer"
                        />
                        <ConnectWallet className="bg-[#6e38cc] capitalize cursor-pointer px-4 py-2 rounded-[20px] font-medium text-[12px] sm:text-[14px] text-white" />
                    </>
                ) : (
                    <div className="flex items-center justify-center gap-2 h-full"> */}
                {/* {user?.loggedIn ? (
                            <button
                                onClick={() => {
                                    dispatch(actions.setLoggedIn(false));
                                    dispatch(actions.setToken(null));
                                    dispatch(actions.setUser(null));
                                }}
                                className="bg-[#6e38cc] capitalize cursor-pointer p-[8px] sm:p-[14px] min-w-[60px] sm:min-w-[90px] rounded-[10px] font-medium text-[12px] sm:text-[14px] text-white"
                            >
                                Logout
                            </button>
                        ) : (
                            <button
                                onClick={() => dispatch(actions.setLogin(true))}
                                className="bg-[#6e38cc] capitalize cursor-pointer p-[8px] sm:p-[14px] min-w-[60px] sm:min-w-[90px] rounded-[10px] font-medium text-[12px] sm:text-[14px] text-white"
                            >
                                Login
                            </button>
                        )} */}
                <ConnectWallet className="bg-[#6e38cc] capitalize cursor-pointer px-4 py-2 rounded-[20px] font-medium text-[12px] sm:text-[14px] text-white" />
                {/* <button className="bg-[#6e38cc] capitalize cursor-pointer px-4 py-2 rounded-[20px] font-medium text-[14px] text-white">
          Connect Wallet
        </button> */}
                {/* </div> */}
                {/* )} */}
            </div>
        </div>
    );
};

export default Navbar;
