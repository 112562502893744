import {Button, Dialog, DialogBody, Spinner} from "@material-tailwind/react"
import React, {useEffect, useState} from "react"
import {FaTimes} from "react-icons/fa"
import {IoMdEye, IoMdEyeOff} from "react-icons/io"
import {getUser} from "../service/api"
import {useDispatch, useSelector} from "react-redux"
import actions from "../redux/user/actions"
import {toast} from "react-toastify"

const AdminLogin = ({login, setLogin, setRegister}) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [payload, setPayload] = useState({
    email: "",
    password: "",
  })
  const [payloadErr, setPayloadErr] = useState({
    email: false,
    password: false,
  })
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState()
  const [passwordShow, setPasswordShow] = useState(false)

  const handleChange = (e) => {
    e.preventDefault()
    setPayload({...payload, [e.target.name]: e.target.value})
    setPayloadErr({...payloadErr, [e.target.name]: false})
  }

  useEffect(() => {
    const reset = setTimeout(() => {
      setErrMsg()
    }, 10000)

    return () => clearTimeout(reset)
  }, [errMsg])
  const handleLogin = () => {
    for (let key in payload) {
      if (payload[key] === null || payload[key] === "") {
        setPayloadErr((prevState) => ({...prevState, [key]: true}))
      }
    }
    if (payload?.email === "" || payload?.password === "") {
      return
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!emailRegex.test(payload?.email)) {
      setErrMsg("Please Enter Valid Email")
      return
    }
    if (payload?.email === "test@gmail.com" && payload?.password === "123123") {
      dispatch(actions.setAdminLogin(false))
    } else {
      setErrMsg("Invalid Credentials")
    }
  }
  return (
    <Dialog
      open={user?.adminLogin}
      //   handler={() => setLogin(!login)}
      animate={{
        mount: {scale: 1, y: 0},
        unmount: {scale: 0.9, y: -100},
      }}
      size="xs"
      className="rounded-lg dark:bg-darkSidebar">
      <DialogBody className="p-0">
        <div className="border-b border-[#E5E5E5] dark:border-darkBorder flex items-center justify-between px-5 py-3">
          <h5 className="text-base font-proxima-bold text-black dark:text-white leading-none">
            Login
          </h5>
          {/* <Button
            className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 rounded-full p-2"
            onClick={() => setLogin(false)}>
            <FaTimes className="w-3 h-3 text-[#777]" />
          </Button> */}
        </div>
        <div className="flex flex-col items-start justify-center gap-3 p-5 w-full">
          {errMsg && <p className="text-red-900 text-[14px]">{errMsg}</p>}
          <div className="flex flex-col items-start justify-center gap-1 w-full">
            <p className="text-black text-[14px]">Email</p>
            <input
              placeholder="Email"
              type="email"
              className={`border ${
                payloadErr?.email ? "border-red-800" : "border-[#e5e5e5]"
              } outline-none text-black rounded-[10px] w-full p-2`}
              value={payload?.email}
              name="email"
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col items-start justify-center gap-1 w-full">
            <p className="text-black text-[14px]">Password</p>{" "}
            <div className="relative w-full">
              <input
                placeholder="******"
                type={passwordShow ? "text" : "password"}
                value={payload?.password}
                name="password"
                onChange={handleChange}
                className={`border ${
                  payloadErr?.password ? "border-red-800" : "border-[#e5e5e5]"
                } outline-none text-black rounded-[10px] w-full p-2`}
              />{" "}
              <Button
                className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-1 right-1"
                onClick={() => setPasswordShow(!passwordShow)}>
                {passwordShow ? (
                  <IoMdEyeOff className="w-5 h-5 text-black" />
                ) : (
                  <IoMdEye className="w-5 h-5 text-black" />
                )}
              </Button>
            </div>
          </div>
          {/* <div className="w-full text-right cursor-pointer text-black text-[14px]">
            Forgot Password?
          </div> */}
          <Button
            onClick={handleLogin}
            className="w-full bg-[#e7b900] cursor-pointer"
            disabled={loading}>
            {loading ? <Spinner className="w-full text-center" /> : "Login"}
          </Button>
          {/* <div
            className="w-full text-center cursor-pointer text-black text-[14px]"
            onClick={handleUser}>
            New User?
          </div> */}
        </div>
      </DialogBody>
    </Dialog>
  )
}

export default AdminLogin
