import { useAddress, useNFT, useSigner } from "@thirdweb-dev/react";
import React, { useEffect, useState } from "react";
import Tree from "react-d3-tree";
import { getClubSize, getReferral } from "../utility/sale";
import { config } from "../config";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../service/api";

const Referral = () => {
    const [treeData, setTreeData] = useState({});
    console.log("%c Line:12 🥤 treeData", "color:#3f7cff", treeData);
    const address = useAddress();
    const signer = useSigner();
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        mainFunctionRef();
    }, [address, signer, loading]);

    useEffect(() => {
        getCountDataMAin();
        // console.log("%c Line:25 🌮 count", "color:#7f2b82", count);
    }, [treeData]);

    const getCountDataMAin = async () => {
        if (treeData && treeData?.children && treeData?.children.length > 0) {
            let count = 0;
            console.log(
                "%c Line:25 🍤 treeData",
                "color:#6ec1c2",
                treeData?.children,
            );
            for (const iterator of treeData.children) {
                console.log(
                    "%c Line:25 🌰 iterator",
                    "color:#6ec1c2",
                    iterator,
                );
                count += await getCountData(iterator);
            }
            setTotalCount(count);
            console.log("%c Line:32 🍫 count", "color:#6ec1c2", count);
        }
    };

    const getCountData = async (data) => {
        let count = 1;
        if (data.children && data.children.length > 0) {
            for (const iterator of data.children) {
                console.log(
                    "%c Line:25 🌰 iterator",
                    "color:#6ec1c2",
                    iterator,
                );
                count += await getCountData(iterator);
            }
            console.log("%c Line:64 🥃 count", "color:#ffdd4d", count);
        }

        return count;
    };

    const getArrOfLength = async (length) => {
        let arr = [];
        for (let index = 0; index <= length; index++) {
            arr.push(index);
        }
        return arr;
    };

    const userName = async (address) => {
        return new Promise(async (resolve) => {
            // const getData = await getUserData(address);
            // console.log("%c Line:30 🍐 getData", "color:#3f7cff", getData);
            resolve(address);
        });
    };

    const mainFunctionRef = async () => {
        return new Promise(async (resolve) => {
            let refAdd = window.location.href.split("address=")[1];
            let addr = refAdd ? refAdd : address;
            const getclubSize = await getClubSize(
                addr,
                config.saleAddress,
                signer,
            );

            let arrMain = await getArrOfLength(parseInt(getclubSize));
            console.log("%c Line:320 🍞 arrMain", "color:#ffdd4d", arrMain);

            let tree = {
                name: await userName(addr),
            };
            let children = [];
            for (const iterator of arrMain) {
                const getREferralAddress = await getReferral(
                    addr,
                    iterator,
                    config.saleAddress,
                    signer,
                );
                // let Data = [];
                for (const i of getREferralAddress) {
                    let d = await fetchReferral(i);
                    console.log("%c Line:341 🥓 d", "color:#7f2b82", d);
                    // Data.push(d);
                    children.push(d);
                }
            }
            tree.children = children;
            console.log("%c Line:345 🍭 tree", "color:#465975", tree);

            setTreeData(tree);
        });
    };

    const fetchReferral = async (address) => {
        return new Promise(async (resolve) => {
            console.log("%c Line:346 🍖 address", "color:#7f2b82", address);
            const getclubSize = await getClubSize(
                address,
                config.saleAddress,
                signer,
            );

            let arrMain = await getArrOfLength(parseInt(getclubSize));

            let tree = {
                name: await userName(address),
            };
            let children = [];
            for (const iterator of arrMain) {
                const getREferralAddress = await getReferral(
                    address,
                    iterator,
                    config.saleAddress,
                    signer,
                );
                let Data = [];
                for (const i of getREferralAddress) {
                    let d = await fetchReferral(i);
                    // Data.push(d);
                    children.push(d);
                }
            }
            tree.children = children;
            console.log("%c Line:383 🍋 tree", "color:#465975", tree);
            resolve(tree);
        });
    };

    return (
        <div>
            {" "}
            <div className="flex items-start w-[90%] sm:w-full justify-center flex-col gap-4">
                <h3 className="text-[#fff] w-full px-4 sm:px-0 text-left font-medium text-[45px] sm:text-[60px]">
                    Referral Tree{" "}
                    <span
                        onClick={() => navigate(-1)}
                        style={{
                            float: "right",
                            marginTop: "20px",
                            cursor: "pointer",
                        }}
                    >
                        <FaArrowAltCircleLeft />
                    </span>
                </h3>
                <div className="flex flex-col sm:flex-row items-center justify-between w-full gap-y-2 p-2 my-2">
                    <p className="text-[16px] text-white sm:text-[18px] font-semibold">
                        Team business : {totalCount * 30}
                    </p>{" "}
                    <p className="text-[16px] text-white sm:text-[18px] font-semibold">
                        Team members : {totalCount}
                    </p>
                    <p className="text-[16px] text-white sm:text-[18px] font-semibold">
                        Royalty level income monthly : 0
                    </p>
                </div>
                <div className="flex tree flex-col mx-auto sm:flex-row w-full px-4 sm:px-8 items-center justify-between min-h-[540px] p-8 gap-5 border-2 border-[#141217] rounded-[12px] bg-transparent">
                    {treeData && (
                        <Tree
                            straight
                            translate={{ x: window.innerWidth / 2, y: 30 }}
                            orientation="vertical"
                            data={treeData}
                            leafNodeClassName="node__root"
                            separation={{ siblings: 4, nonSiblings: 4 }}
                            pathFunc="step"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Referral;
