import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { Binance } from "@thirdweb-dev/chains";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThirdwebProvider activeChain={Binance} supportedChains={[Binance]}>
            <ToastContainer />
            <Provider store={store}>
                <App />{" "}
            </Provider>
        </ThirdwebProvider>
    </React.StrictMode>,
);

reportWebVitals();
