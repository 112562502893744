import React, { useEffect, useState } from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import { MdOutlineArrowOutward } from "react-icons/md"
import { FaCircleInfo, FaRegCircleDot, FaRegCopy } from "react-icons/fa6"
import img1 from "../Assets/Images/img-1.png"
import img2 from "../Assets/Images/img-2.png"
import Aos from "aos"
import { useAddress, useSigner, useBalance } from "@thirdweb-dev/react"
import { toast } from "react-toastify"
import { config } from "../config"
import {
    addressBalance,
    getStatus,
    getMaxAmount,
    getMinAmount,
    hardCapValue,
    userClaimableToken,
    depositTokenFun,
    getTotalInvested,
    checkIsWhitelisted,
    checkIsPublic,
    getReferral,
    getClubSize,
} from "../utility/sale"
import {
    getTokenBalance,
    approveToken,
    getTokenAllowance,
} from "../utility/token"
import Tree from "react-d3-tree"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useLocation, useNavigate } from "react-router-dom"
import LoginModel from "./LoginModel"
import RegisterModal from "./RegisterModal"
import { useDispatch, useSelector } from "react-redux"
import actions from "../redux/user/actions"
import { addReferal } from "../service/api"
var Scroll = require("react-scroll")

var Link = Scroll.Link
var DirectLink = Scroll.DirectLink
var Element = Scroll.Element
var Events = Scroll.Events
var scroll = Scroll.animateScroll
var scrollSpy = Scroll.scrollSpy
const MainPage = () => {
    useEffect(() => {
        Aos.init({
            duration: 1000,
            once: true,
        })
    }, [])
    const orgChart = {
        name: "Jhon",
        children: [
            {
                name: "Ethan",
                attributes: {
                    invest: "$100",
                },
                children: [
                    {
                        name: "Tom",
                        attributes: {
                            invest: "$100",
                        },
                        children: [
                            {
                                name: "Currun",
                            },
                        ],
                    },
                    {
                        name: "David",
                        attributes: {
                            invest: "$100",
                        },
                        children: [
                            {
                                name: "Warner",
                            },
                        ],
                    },
                ],
            },
            {
                name: "Keith",
                attributes: {
                    invest: "$100",
                },
                children: [
                    {
                        name: "Mark",
                        attributes: {
                            invest: "$100",
                        },
                        children: [
                            {
                                name: "Peter",
                            },
                        ],
                    },
                    {
                        name: "Sam",
                        attributes: {
                            invest: "$100",
                        },
                        children: [
                            {
                                name: "Kean",
                            },
                        ],
                    },
                ],
            },
        ],
    }

    const [treeData, setTreeData] = useState({})
    const address = useAddress()
    const signer = useSigner()
    const balance = useBalance()
    const [saleActive, setSaleActive] = useState(false)
    const [totalValue, setTotalValue] = useState(0)
    const [contractBalance, setContractBalance] = useState(0)
    const [userBalance, setUserBalance] = useState(0)
    const [contractBalanceTotal, setContractBalanceTotal] = useState(0)
    const user = useSelector((state) => state.user)
    // console.log("%c Line:128 🍖 user", "color:#2eafb0", user)
    const [login, setLogin] = useState(true)
    const [register, setRegister] = useState(false)
    const setAmountData = async (e) => {
        setAmount(e.target.value)
        setGetAmount(e.target.value * 840)
    }
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [amount, setAmount] = useState(0.25)
    const [getAmount, setGetAmount] = useState(0)
    const [selectedToken, setSelectedToken] = useState("USDT")
    const [selectedTokenBalance, setSelectedTokenBalance] = useState("USDT")
    const [claimable, setClaimable] = useState(0)
    const [approvedBalance, setApprovedBalance] = useState(0)
    const [minAmount, setMinAmount] = useState(0.01)
    const [maxAmount, setMaxAmount] = useState(1)
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState("BNB")
    const [whitelist, setWhitelist] = useState(false)
    const [publicSale, setPublicSale] = useState(false)
    const [referralAddress, setReferralAddress] = useState("")

    // useEffect(() => {
    //     if (!address || !signer) return;
    //     const getData = async () => {
    //         let data = await getStatus(config.saleAddress, signer);
    //         setSaleActive(data);
    //     };
    //     getData();
    // }, [address, signer]);

    // useEffect(() => {
    //     if (!address || !signer) return;
    //     const getData = async () => {
    //         let data = await hardCapValue(config.saleAddress, signer);
    //         setTotalValue(parseFloat(data));
    //     };
    //     getData();
    // }, [address, signer]);

    // useEffect(() => {
    //     if (!address || !signer) return;
    //     const getData = async () => {
    //         let getTokenBal = await getTotalInvested(
    //             config.saleAddress,
    //             signer,
    //         );
    //         setContractBalanceTotal(getTokenBal);
    //     };
    //     getData();
    // }, [address, signer]);

    // useEffect(() => {
    //     if (!address || !signer) return;
    //     const getData = async () => {
    //         let data = await getMinAmount(config.saleAddress, signer);
    //         setMinAmount(data);
    //     };
    //     getData();
    // }, [address, signer, loading]);

    // useEffect(() => {
    //     if (!address || !signer) return;
    //     const getData = async () => {
    //         let data = await getMaxAmount(config.saleAddress, signer);
    //         setMaxAmount(data);
    //     };
    //     getData();
    // }, [address, signer, loading]);

    useEffect(() => {
        if (!address || !signer) return
        const getData = async () => {
            let getTokenBal = await addressBalance(config.saleAddress, signer)
            setContractBalance(parseFloat(getTokenBal))
        }
        getData()
    }, [address, signer, selectedToken])

    useEffect(() => {
        if (!address || !signer) return
        const getData = async () => {
            let getTokenBal = await addressBalance(address, signer)
            // let { data } = balance;
            // console.log("%c Line:149 🍿 data", "color:#2eafb0", data);
            // setWalletBalance(parseFloat(data?.displayValue));

            setUserBalance(parseFloat(getTokenBal).toFixed(3))
        }
        getData()
    }, [address, signer, selectedToken])

    useEffect(() => {
        if (!address || !signer) return
        const getData = async () => {
            let data = await userClaimableToken(config.saleAddress, signer, address)
            console.log("%c Line:231 🥒 data", "color:#e41a6a", data)
            setClaimable(parseFloat(data))
        }
        getData()
    }, [address, signer, loading])

    // useEffect(() => {
    //     if (!address || !signer) return;
    //     const getData = async () => {
    //         let data = await checkIsWhitelisted(
    //             config.saleAddress,
    //             signer,
    //             address,
    //         );
    //         setWhitelist(data);
    //     };
    //     getData();
    // }, [address, signer, loading]);

    // useEffect(() => {
    //     if (!address || !signer) return;
    //     const getData = async () => {
    //         let data = await checkIsPublic(config.saleAddress, signer);
    //         setPublicSale(data);
    //     };
    //     getData();
    // }, [address, signer, loading]);

    useEffect(() => {
        if (!address || !signer) return
        const getData = async () => {
            // let data = await getTokenAllowance(
            //     address,
            //     selectedToken,
            //     config.saleAddress,
            //     signer,
            // );
            // setApprovedBalance(parseFloat(data));
        }
        getData()
    }, [address, signer, loading])

    const allowToken = async () => {
        setLoading(true)

        let data = await approveToken(
            address,
            selectedToken,
            config.saleAddress,
            signer,
        )
        console.log("%c Line:131 🍻 data", "color:#465975", data)
        if (!data) {
            toast.error(`Error While Transaction`)
            setLoading(false)
            return
        }
        toast.success(`approved successfully`)
        setLoading(false)
    }

    const depositToken = async () => {
        if (!address) {
            toast.error(`Please Connect Wallet`)
            return
        }
        // if (address !== user?.user?.walletAddress) {
        //     toast.error(`Don't have enough balance`)
        //     return
        // }
        setLoading(true)
        if (!amount) {
            toast.error(`Enter min amount ${minAmount}`)
            setLoading(false)
            return
        }
        let refAdd = window.location.href.split("referralAddress=")[1]

        let data = await depositTokenFun(
            refAdd ? refAdd : config.defaultReferral,
            config.saleAddress,
            signer,
            amount,
        )
        if (!data) {
            toast.error(`Error While Transaction`)
            setLoading(false)
            return
        }
        toast.success(`Transaction Complete`)

        // let body = {
        //   walletAddress: address,
        //   refferalAddress: refAdd ? refAdd : config.defaultReferral,
        // }
        // addReferal({body: body, headers: {Authorization: user?.token}})
        //   .then((res) => {
        //     console.log("%c Line:323 🍬 res", "color:#fca650", res)
        //   })
        //   .catch((e) => {
        //     console.log("%c Line:325 🍞 e", "color:#ea7e5c", e)
        //   })
        // setAmount(0)
        setLoading(false)
    }

    useEffect(() => {
        mainFunctionRef()
    }, [address, signer, loading])

    const getArrOfLength = async (length) => {
        let arr = []
        for (let index = 0; index <= length; index++) {
            arr.push(index)
        }
        return arr
    }
    const mainFunctionRef = async () => {
        return new Promise(async (resolve) => {
            const getclubSize = await getClubSize(address, config.saleAddress, signer)

            let arrMain = await getArrOfLength(parseInt(getclubSize))
            console.log("%c Line:320 🍞 arrMain", "color:#ffdd4d", arrMain)

            let tree = {
                name: address,
            }
            let children = []
            for (const iterator of arrMain) {
                const getREferralAddress = await getReferral(
                    address,
                    iterator,
                    config.saleAddress,
                    signer,
                )
                console.log(
                    "%c Line:334 🌽 getREferralAddress",
                    "color:#465975",
                    getREferralAddress,
                )
                // let Data = [];
                for (const i of getREferralAddress) {
                    console.log("%c Line:340 🥐 i", "color:#6ec1c2", i)
                    let d = await fetchReferral(i)
                    console.log("%c Line:341 🥓 d", "color:#7f2b82", d)
                    // Data.push(d);
                    children.push(d)
                }
            }
            tree.children = children
            console.log("%c Line:345 🍭 tree", "color:#465975", tree)

            setTreeData(tree)
        })
    }

    const fetchReferral = async (address) => {
        return new Promise(async (resolve) => {
            console.log("%c Line:346 🍖 address", "color:#7f2b82", address)
            const getclubSize = await getClubSize(address, config.saleAddress, signer)

            let arrMain = await getArrOfLength(parseInt(getclubSize))

            let tree = {
                name: `${address}`,
            }
            let children = []
            for (const iterator of arrMain) {
                const getREferralAddress = await getReferral(
                    address,
                    iterator,
                    config.saleAddress,
                    signer,
                )
                let Data = []
                for (const i of getREferralAddress) {
                    let d = await fetchReferral(i)
                    // Data.push(d);
                    children.push(d)
                }
            }
            tree.children = children
            console.log("%c Line:383 🍋 tree", "color:#465975", tree)
            resolve(tree)
        })
    }

    return (
        <>
            <Navbar />
            <LoginModel login={login} setLogin={setLogin} setRegister={setRegister} />
            <RegisterModal
                register={register}
                setRegister={setRegister}
                setLogin={setLogin}
            />
            <div className="w-full mx-auto sm:w-[80%] flex flex-col gap-6 items-center justify-center">
                <div className="flex flex-col-reverse w-full sm:flex-row gap-3 items-center justify-start my-12 sm:my-20 py-4">
                    <div
                        className="flex flex-col w-[90%] sm:w-[70%] items-start justify-center gap-3 py-2"
                        data-aos="fade-down">
                        <div className="flex flex-col items-start justify-center gap-0 py-2">
                            <h3 className="text-[#fff] leading-[50px] sm:leading-[70px] font-medium text-[45px] text-left sm:text-[60px]">
                                Earn rewards while
                            </h3>
                            <h3 className="text-[#fff] leading-[50px] sm:leading-[70px] font-medium text-[45px] text-left sm:text-[60px]">
                                securing the BSC PoS
                            </h3>
                            <h3 className="text-[#fff] leading-[50px] sm:leading-[70px] font-medium text-[45px] text-left sm:text-[60px]">
                                network
                            </h3>
                        </div>
                        <p className="text-[#67666e] font-medium text-[16px]">
                            Anyone can join the growing network and wholesome passive income.
                        </p>

                        <div className="gap-3 flex my-4">
                            {loading ? (
                                <button className="bg-[#e7b900] capitalize cursor-pointer px-8 py-2 rounded-[20px] font-medium text-[14px] text-white">
                                    Loading...
                                </button>
                            ) : claimable > 0 ? (
                                <>

                                </>
                            ) : <div className="flex flex-col gap-2">
                                <button
                                    className="bg-[#e7b900] capitalize cursor-pointer px-8 py-2 rounded-[20px] font-medium text-[14px] text-white"
                                    onClick={depositToken}>
                                    Deposit
                                </button>
                            </div>}
                            {/* <Link
                                to="stake"
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className="bg-[#e7b900] flex gap-2 justify-center items-center capitalize cursor-pointer px-4 py-2 rounded-[20px] font-medium text-[14px] text-white hover:bg-white hover:text-black">
                                Join Telegram
                                <MdOutlineArrowOutward className="size-[26px]" />
                            </Link> */}
                            <a
                                className="bg-[#18161c] flex gap-2 justify-center items-center capitalize cursor-pointer px-4 py-2 rounded-[20px] font-medium text-[14px] text-white"
                                target="_blank"
                                href="https://t.me/growbnbonline"
                            >
                                Join Telegram         </a>
                            <a
                                className="bg-[#18161c] flex gap-2 justify-center items-center capitalize cursor-pointer px-4 py-2 rounded-[20px] font-medium text-[14px] text-white"
                                target="_blank"
                                href="/GROWBNB.pdf"
                            >
                                Go To Doc            </a>

                        </div>
                    </div>

                    <iframe
                        type="video/mp4"
                        loop
                        autoPlay
                        width={400}
                        height={400}
                        className="bg-transparent video"
                        src="https://player.vimeo.com/video/791475081?h=a0b62c3daa&badge=0&autopause=0&player_id=0&app_id=58479&loop=1&autoplay=1&background=1"
                    />
                </div>
                {/* <div className="flex flex-col w-full ietms-center sm:items-start justify-center gap-3 py-2">
          <h3 className="text-[#fff] w-full px-4 sm:px-0 text-left font-medium text-[45px] sm:text-[60px]">
            Why deposit BNB?
          </h3>
          <div className="flex gap-5 flex-col sm:flex-row items-center mt-5 justify-center">
            <div
              data-aos="fade-right"
              className="flex flex-col w-[90%] sm:w-[33%] items-start justify-start min-h-0 sm:min-h-[500px] p-8 gap-5 border-2 border-[#141217] rounded-[12px] bg-transparent hover:bg-[#6666663f] transition duration-300 ease-in-out">
              <img src={img1} alt="" className="bg-transparent size-[65px]" />
              <div className="flex flex-col gap-2 items-start mt-10 justify-center">
                <h3 className="text-[#fff] w-full text-left my-3 font-medium text-[26px] sm:text-[30px]">
                  Earn Rewards
                </h3>
                <p className="text-[#67666e] font-medium text-[16px] sm:text-[18px] text-left">
                  2% of the total supply of 10 billion BNB is allocated to
                  fund staking rewards. As a validator, you set your own
                  commission for accepting delegations to your node. There are
                  also annual incentives available!
                </p>
              </div>
            </div>
            <div
              data-aos="fade-down"
              className="flex flex-col w-[90%] sm:w-[33%] items-start justify-start min-h-0 sm:min-h-[500px] p-8 gap-5 border-2 border-[#141217] rounded-[12px] bg-transparent hover:bg-[#6666663f] transition duration-300 ease-in-out">
              <img src={img2} alt="" className="bg-transparent size-[65px]" />
              <div className="flex flex-col gap-2 items-start mt-10 justify-center">
                <h3 className="text-[#fff] w-full text-left my-3 font-medium text-[26px] sm:text-[30px]">
                  Stake Sustainably
                </h3>
                <p className="text-[#67666e] font-medium text-[16px] sm:text-[18px] text-left">
                  You do not need to use energy-intensive computers to stake,
                  which lessens the environmental impact. Each node consumes
                  only 546.07 kWh per year.
                </p>
              </div>
            </div>
            <div
              data-aos="fade-left"
              className="flex flex-col w-[90%] sm:w-[33%] items-start justify-start min-h-0 sm:min-h-[500px] p-8 gap-5 border-2 border-[#141217] rounded-[12px] bg-transparent hover:bg-[#6666663f] transition duration-300 ease-in-out">
              <img src={img2} alt="" className="bg-transparent size-[65px]" />
              <div className="flex flex-col gap-2 items-start mt-10 justify-center">
                <h3 className="text-[#fff] w-full text-left my-3 font-medium text-[26px] sm:text-[30px]">
                  Build the future
                </h3>
                <p className="text-[#67666e] font-medium text-[16px] sm:text-[18px] text-left">
                  BSC PoS is one of the most used protocols in the world and
                  continues to grow. By staking, you can help to build the
                  technology of Web3.
                </p>
              </div>
            </div>
          </div>
        </div> */}
                <div
                    id="stake"
                    className="flex items-start w-[90%] sm:w-full justify-center flex-col gap-4 stake">
                    <h3 className="text-[#fff] w-full px-4 sm:px-0 text-left font-medium text-[45px] sm:text-[60px]">
                        Deposit BNB
                    </h3>
                    <div className="flex flex-col sm:flex-row w-full px-4 sm:px-8 items-center justify-between min-h-[240px] p-8 gap-5 border-2 border-[#141217] rounded-[12px] bg-transparent">
                        <div className="flex flex-col w-full items-start justify-center gap-4">
                            {" "}
                            <div
                                className="bg-green-100 w-full text-green-700 p-2 rounded-[6px] pl-4 flex justify-start items-center"
                                role="alert">
                                <div className="mr-4">
                                    <FaCircleInfo />
                                </div>
                                <div>
                                    <p>You can stake 0.25 BNB only</p>
                                </div>
                            </div>
                            <div className="flex flex-col sm:flex-row w-full justify-between items-center sm:items-start gap-2">
                                <div className="flex flex-row justify-between items-center h-[40px] w-full sm:w-[60%] rounded-[10px] bg-transparent p-2 border border-[#ccc7] transition duration-300 ease-in-out hover:border-[#ccc]">
                                    <input
                                        className="rounded-[10px] bg-transparent p-2 outline-none text-white"
                                        value={amount}
                                        disabled={true}
                                    // onChange={(e) => {
                                    //   setAmount(e.target.value)
                                    // }}
                                    />
                                    <p className="text-[#67666e] w-full font-medium text-[12px] text-right sm:text-[14px] ">
                                        Bal: {userBalance} BNB
                                    </p>
                                </div>

                                <div className="flex flex-col sm:flex-row items-center w-full sm:w-[40%] sm:items-start justify-end gap-2 ">
                                    {loading ? (
                                        <button className="bg-[#e7b900] capitalize cursor-pointer px-8 py-2 rounded-[20px] font-medium text-[14px] text-white">
                                            Loading...
                                        </button>
                                    ) : claimable > 0 ? (
                                        <>
                                            <div className="flex flex-col gap-2">
                                                <button
                                                    className="bg-[#e7b900] capitalize cursor-pointer px-8 py-2 rounded-[20px] font-medium text-[14px] text-white"
                                                    disabled={true}>
                                                    Already Staked
                                                </button>
                                                <CopyToClipboard
                                                    text={`https://${window.location.host}?referralAddress=${address}`}
                                                    onCopy={() => toast.success("Link Copied !!")}>
                                                    <button className="bg-[#e7b900] flex items-center justify-center gap-1 capitalize cursor-pointer px-8 py-2 rounded-[20px] font-medium text-[14px] text-white">
                                                        Copy Your Referral Link
                                                        <FaRegCopy className="fill-white" />
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </>
                                    ) : (
                                        <>

                                            <button
                                                className="bg-[#e7b900] capitalize cursor-pointer px-8 py-2 rounded-[20px] font-medium text-[14px] text-white"
                                                onClick={depositToken}>
                                                Deposit
                                            </button>
                                            <CopyToClipboard
                                                text={`https://${window.location.host}?referralAddress=${address}`}
                                                onCopy={() => toast.success("Link Copied !!")}>
                                                <button className="bg-[#e7b900] flex items-center justify-center gap-1 capitalize cursor-pointer px-8 py-2 rounded-[20px] font-medium text-[14px] text-white">
                                                    Copy Your Referral Link
                                                    <FaRegCopy className="fill-white" />
                                                </button>
                                            </CopyToClipboard>
                                        </>
                                    )}
                                    {claimable > 0 && (
                                        <button
                                            className="bg-[#e7b900] capitalize cursor-pointer px-8 py-2 rounded-[20px] font-medium text-[14px] text-white"
                                            onClick={() => navigate("/referral")}>
                                            Referral Tree
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full ietms-center sm:items-start justify-center gap-3 py-10">
                    <div className="flex flex-col sm:flex-row w-full items-center sm:items-start justify-center gap-2 sm:gap-10 py-4">
                        <div className="flex flex-col w-[90%] px-4 sm:px-0 sm:w-[30%]ietms-center sm:items-start justify-center gap-3 py-2">
                            <h3 className="text-[#fff] w-full text-left font-medium text-[45px] sm:text-[60px]">
                                Why deposit BNB?
                            </h3>
                            <p className="text-[#67666e] font-medium text-[14px] sm:text-[16px] border-b border-[#141217] pb-6 text-left">
                                BSC PoS chain is run on the Proof of Stake mechanism. Anyone
                                can seek to become a validator on the mainnet.
                            </p>
                            <p className="text-[#67666e] font-medium text-[14px] sm:text-[16px] py-4 text-left">
                                Check how much reward you can earn with your BNB
                            </p>
                            <a
                                className="bg-[#e7b900] capitalize cursor-pointer px-8 py-2 rounded-[20px] font-medium text-[14px] text-white"
                                target="_blank"
                                href="/GROWBNB.pdf"
                            >
                                go to reward calculator
                            </a>
                        </div>
                        <div className="w-0 sm:w-[30%] "></div>
                        <div className="flex flex-col w-[90%] sm:w-[40%] px-4 sm:px-0 items-center justify-center min-h-[240px] p-8 gap-5 border-2 border-[#141217] rounded-[12px] bg-transparent">
                            <div className="flex flex-col gap-2 items-center justify-center">
                                <h3 className="text-[#fff] w-full text-center my-3 font-medium text-[26px] sm:text-[30px]">
                                    100% CRP
                                </h3>
                                <p className="text-[#67666e] font-medium text-[16px] sm:text-[18px] text-center">
                                    CURRENT REWARD PERCENTAGE
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex gap-5 flex-col sm:flex-row items-center justify-center">
            <div
              data-aos="flip-left"
              className="flex flex-col w-[90%] sm:w-[50%] items-start justify-start min-h-[550px] p-8 gap-3 border-2 border-[#141217] rounded-[12px] bg-transparent">
              <iframe
                src="https://player.vimeo.com/video/791476632?h=a0b62c3daa&badge=0&autopause=0&player_id=0&app_id=58479&loop=1&autoplay=1&background=1"
                width={200}
                height={200}
                alt=""
                loop
                autoPlay
                className="bg-transparent"
              />
              <div className="flex flex-col gap-2 items-start mt-10 justify-center">
                <h3 className="text-[#fff] w-full text-left my-3 font-medium text-[26px] sm:text-[30px]">
                  Become a Validator
                </h3>
                <p className="text-[#67666e] font-medium text-[18px] sm:text-[16px] text-left">
                  Validators verify transactions and add new blocks on the
                  blockchain. In return, they earn rewards. Validator nodes are
                  units on the BSC blockchain that participate in consensus
                  group work and commit checkpoints on the Ethereum Mainnet.
                </p>
              </div>
            </div>
            <div
              data-aos="flip-right"
              className="flex flex-col w-[90%] sm:w-[50%] items-start justify-start min-h-[550px] p-8 gap-3 border-2 border-[#141217] rounded-[12px] bg-transparent">
              <iframe
                src="https://player.vimeo.com/video/791476625?h=a0b62c3daa&badge=0&autopause=0&player_id=0&app_id=58479&loop=1&autoplay=1&background=1"
                width={200}
                height={200}
                alt=""
                loop
                autoPlay
                className="bg-transparent"
              />{" "}
              <div className="flex flex-col gap-2 items-start mt-10 justify-center">
                <h3 className="text-[#fff] w-full text-left my-3 font-medium text-[26px] sm:text-[30px]">
                  Become a Delegator
                </h3>
                <p className="text-[#67666e] font-medium text-[14px] sm:text-[16px] text-left">
                  Participate as a delegator to earn rewards and contribute to
                  network security. Delegate staking tokens to a validator of
                  your choice and share risks and rewards.
                </p>
              </div>
            </div>
          </div> */}
                </div>
                <div className="flex flex-col sm:flex-row w-full ietms-center sm:items-start justify-center gap-0 sm:gap-10 py-4">
                    <div className="flex flex-col w-full sm:w-[45%] ietms-center sm:items-start justify-center py-2">
                        <h3 className="text-[#fff] leading-[50px] sm:leading-[70px] w-full px-4 sm:px-0 text-left font-medium text-[45px] sm:text-[60px]">
                            Validator
                        </h3>
                        <h3 className="text-[#fff] leading-[50px] sm:leading-[70px] w-full px-4 sm:px-0 text-left font-medium text-[45px] sm:text-[60px]">
                            Performance
                        </h3>
                        <h3 className="text-[#fff] leading-[50px] sm:leading-[70px] w-full px-4 sm:px-0 text-left font-medium text-[45px] sm:text-[60px]">
                            Metric
                        </h3>
                        <p className="text-[#67666e] font-medium text-[14px] px-4 sm:px-0 sm:text-[16px] py-3 text-left">
                            Validator Performance Metric helps in self-regulating network
                            participation to an agreed set of parameters through:
                        </p>
                    </div>
                    <div className="w-0 sm:w-[10%]"></div>
                    <div className="flex flex-col w-[90%] sm:w-[45%] items-start justify-center min-h-[240px] p-8 gap-5 rounded-[12px] bg-transparent">
                        <div className="grid grid-cols-[16px_1fr] flex-row gap-4 items-start justify-center">
                            <FaRegCircleDot className="fill-[#ccc] mt-[5px] size-[16px]" />
                            <p className="text-[#67666e] font-medium text-[16px] sm:text-[18px] text-left">
                                Setting up a Performance Benchmark for a measurement period
                            </p>
                        </div>
                        <div className="grid grid-cols-[16px_1fr] gap-4 items-start justify-center">
                            <FaRegCircleDot className="fill-[#ccc] mt-[5px] size-[16px]" />
                            <p className="text-[#67666e] font-medium text-[16px] sm:text-[18px] text-left">
                                Identifying healthy and under performing validators
                            </p>
                        </div>
                        <div className="grid grid-cols-[16px_1fr] gap-4 items-start justify-center">
                            <FaRegCircleDot className="fill-[#ccc] mt-[5px] size-[16px]" />
                            <p className="text-[#67666e] font-medium text-[16px] sm:text-[18px] text-left">
                                Initiating communication with under performing validators for
                                remedy
                            </p>
                        </div>
                        <div className="grid grid-cols-[16px_1fr] gap-4 items-start justify-center">
                            <FaRegCircleDot className="fill-[#ccc] mt-[5px] size-[16px]" />
                            <p className="text-[#67666e] font-medium text-[16px] sm:text-[18px] text-left">
                                Showcasing validators' performances regularly
                            </p>
                        </div>
                        <div className="grid grid-cols-[16px_1fr] gap-4 items-start justify-center">
                            <FaRegCircleDot className="fill-[#ccc] mt-[5px] size-[16px]" />
                            <p className="text-[#67666e] font-medium text-[16px] sm:text-[18px] text-left">
                                Initiating off-boarding of unhealthy validators from the network
                            </p>
                        </div>
                    </div>
                </div>

                <div className="hidden items-start w-[90%] sm:w-full justify-center flex-col gap-4">
                    <h3 className="text-[#fff] w-full px-4 sm:px-0 text-left font-medium text-[45px] sm:text-[60px]">
                        Referral Tree
                    </h3>
                    <div className="flex tree flex-col sm:flex-row w-full px-4 sm:px-8 items-center justify-between min-h-[540px] p-8 gap-5 border-2 border-[#141217] rounded-[12px] bg-transparent">
                        {treeData && (
                            <Tree
                                straight
                                orientation="vertical"
                                data={treeData}
                                leafNodeClassName="node__root"
                                separation={{ siblings: 4, nonSiblings: 4 }}
                                pathFunc="step"
                            />
                        )}
                    </div>
                </div>
                <div className="w-[100vw] relative">
                    <iframe
                        src="https://player.vimeo.com/video/791149043?h=a0b62c3daa&badge=0&autopause=0&player_id=0&app_id=58479&loop=1&autoplay=1&background=1"
                        autoPlay
                        loop
                        width="100%"
                        height={500}
                        className="relative slider-video"
                    />
                    {/* <div className="flex flex-col w-full relative sm:absolute top-[40%] left-[0%] sm:left-[10%] mb-8 items-center sm:items-start justify-center z-10 p-3 gap-3 sm:w-[80%] mr-auto">
                        <p className="text-blue-500  text-[14px]">STAY UP TO DATE</p>
                        <p className="text-white  text-[20px]">Get our newsletter</p>
                        <input
                            placeholder="Enter your email"
                            className="rounded-[10px] h-[55px] bg-black p-2 border border-[#ccc7] transition duration-300 ease-in-out hover:border-[#ccc]"
                        />
                    </div> */}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MainPage
